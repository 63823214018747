<template>
  <div v-if="isLoading"><base-spinner /></div>
  <div v-else class="form-wrapper">
    <h1>Add Trial</h1>
    <el-form
      ref="addTrialForm"
      :model="addTrialForm"
      :rules="formRules"
      label-position="top"
    >
      <el-form-item label="Trial Name" prop="trialName">
        <el-input
          v-model="addTrialForm.trialName"
          :clearable="true"
          placeholder="Trial Name"
        ></el-input>
      </el-form-item>
      <el-form-item label="Event Type" prop="eventType">
        <el-select
          v-model="addTrialForm.eventType"
          filterable
          placeholder="Event Type"
          style="width: 100%"
        >
          <el-option
            v-for="type in eventTypes"
            :key="type"
            :label="type"
            :value="type"
          ></el-option>
        </el-select>
      </el-form-item>

      <section>
        <el-form-item
          label="Sanctioning Body"
          prop="sanctionBody"
          style="width: 35%"
        >
          <el-select
            v-model="addTrialForm.sanctionBody"
            filterable
            placeholder="Sanctioning Body"
          >
            <el-option
              v-for="sanctionBody in sanctionBodies"
              :key="sanctionBody"
              :label="sanctionBody"
              :value="sanctionBody"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="Start Date" prop="startDate" style="width: 31%">
          <el-date-picker
            v-model="addTrialForm.startDate"
            type="date"
            placeholder="Start Date"
            format="MM/DD/YYYY"
            style="width: 90%"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="End Date" prop="endDate" style="width: 31%">
          <el-date-picker
            v-model="addTrialForm.endDate"
            type="date"
            placeholder="End Date"
            format="MM/DD/YYYY"
            :default-value="endDateDefault"
            style="width: 90%"
          ></el-date-picker>
        </el-form-item>
      </section>

      <section>
        <el-form-item
          label="Running Surface"
          prop="runningSurface"
          class="half"
        >
          <el-select
            v-model="addTrialForm.runningSurface"
            filterable
            placeholder="Running Surface"
          >
            <el-option
              v-for="runningSurface in runningSurfaces"
              :key="runningSurface"
              :label="runningSurface"
              :value="runningSurface"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="Outside or Indoors"
          prop="outsideIndoors"
          class="half"
        >
          <el-select
            v-model="addTrialForm.outsideIndoors"
            filterable
            placeholder="Outside or Indoors"
          >
            <el-option
              v-for="outsideIndoors in outsideIndoors"
              :key="outsideIndoors"
              :label="outsideIndoors"
              :value="outsideIndoors"
            ></el-option>
          </el-select>
        </el-form-item>
      </section>

      <el-form-item label="Location" prop="location" style="width: 100%">
        <el-autocomplete
          style="width: 98%"
          v-model="addTrialForm.location"
          :clearble="true"
          placeholder="Location"
          :fetch-suggestions="getOwnerLocations"
          :trigger-on-focus="false"
          @select="handleSelect"
        ></el-autocomplete>
      </el-form-item>

      <section>
        <el-form-item label="Street 1" prop="street1" class="half">
          <el-input
            v-model="addTrialForm.street1"
            :clearble="true"
            placeholder="Street 1"
          ></el-input>
        </el-form-item>
        <el-form-item label="Street 2" prop="street2" class="half">
          <el-input
            v-model="addTrialForm.street2"
            :clearble="true"
            placeholder="Street 2"
          ></el-input>
        </el-form-item>
      </section>

      <section>
        <el-form-item label="City" prop="city" style="width: 48%">
          <el-input
            v-model="addTrialForm.city"
            :clearble="true"
            placeholder="City"
          ></el-input>
        </el-form-item>
        <el-form-item label="State" prop="state" style="width: 18%">
          <el-input
            v-model="addTrialForm.state"
            :clearble="true"
            v-maska="'AA'"
            placeholder="State"
          ></el-input>
        </el-form-item>
        <el-form-item label="Zip" prop="zip" style="width: 27%">
          <el-input
            v-model="addTrialForm.zip"
            :clearble="true"
            v-maska="'#####'"
            placeholder="Zip"
          ></el-input>
        </el-form-item>
      </section>

      <div class="buttons">
        <base-button color="tertiary" @click="handleCancel">Cancel</base-button>
        <base-button color="secondary" @click.prevent="submitForm">{{
          getButtonText
        }}</base-button>
      </div>
    </el-form>
  </div>
</template>

<script>
// import { onBeforeMount } from "vue";
import { Trial } from "@/models/trialModel.js";
import { getTrialLocations } from "../../routes/trial";

import {
  eventTypes,
  sanctionBodies,
  runningSurfaces,
  outsideIndoors,
} from "@/utils/generalFormData";

export default {
  props: { addOrEdit: String, trialId: String },
  data() {
    return {
      isLoading: false,
      addTrialForm: {
        trialName: this.getFormValue("trialName"),
        eventType: this.getFormValue("eventType"),
        sanctionBody: this.getFormValue("sanctionBody"),
        startDate: this.getDateValue("startDate"),
        endDate: this.getDateValue("endDate"),
        runningSurface: this.getFormValue("runningSurface"),
        outsideIndoors: this.getFormValue("outsideIndoors"),
        location: this.getFormValue("location"),
        street1: this.getFormValue("street1"),
        street2: this.getFormValue("street2"),
        city: this.getFormValue("city"),
        state: this.getFormValue("state"),
        zip: this.getFormValue("zip"),
      },
      formRules: {
        trialName: {
          required: true,
          message: "Please enter trial name",
          trigger: "blur",
        },
        eventType: {
          required: true,
          message: "Please enter event type",
          trigger: "blur",
        },
        sanctionBody: {
          required: true,
          message: "Please enter sanctioning body",
          trigger: "blur",
        },
        dateRange: {
          required: true,
          message: "Please enter trial dates",
          trigger: "blur",
        },
        street1: {
          required: true,
          message: "Please enter street address",
          trigger: "blur",
        },
        city: {
          required: true,
          message: "Please enter city",
          trigger: "blur",
        },
        state: {
          required: true,
          message: "Please enter state",
          trigger: "blur",
        },
      },
      eventTypes: eventTypes,
      sanctionBodies: sanctionBodies,
      runningSurfaces: runningSurfaces,
      outsideIndoors: outsideIndoors,
      locations: [],
    };
  },
  computed: {
    getToken() {
      return this.$store.getters["user/getUserTokens"].idToken;
    },
    getUserId() {
      return this.$store.getters["user/getUserId"];
    },
    endDateDefault() {
      return new Date(this.addTrialForm.startDate);
    },
    getButtonText() {
      return this.addOrEdit === "add" || !this.addOrEdit
        ? "Add Trial"
        : "Update Trial";
    },
  },
  watch: {
    endDateDefault(updatedDate) {
      this.addTrialForm.endDate = updatedDate;
    },
    getToken() {
      this.loadTrialLocations();
    },
    getUserId() {
      this.loadTrialLocations();
    },
  },
  methods: {
    handleCancel() {
      console.log("handle cancel");
    },

    getFormValue(formValue) {
      return this.addOrEdit === "edit"
        ? this.$store.getters["trial/getTrialToEdit"][formValue]
        : "";
    },
    getDateValue(dateValue) {
      return this.addOrEdit === "edit"
        ? this.$store.getters["trial/getTrialToEdit"][dateValue]
        : new Date();
    },
    submitForm() {
      this.isLoading = true;
      this.$refs["addTrialForm"].validate(async (valid) => {
        if (valid) {
          try {
            let successMessage = "Trial Added";

            if (this.addOrEdit === "add" || !this.addOrEdit) {
              const trial = new Trial(this.addTrialForm);

              await this.$store.dispatch("trial/createTrial", trial);
              successMessage = "Trial Added";
            } else if (this.addOrEdit === "edit") {
              let trial = new Trial(this.addTrialForm);

              // remove _id so backend doesnt' generate error
              delete trial._id;

              // remove trialRuns, trialClassesByDate and trialRegistrations
              // so they don't get overwritten in the datbase
              delete trial.trialRuns;
              delete trial.trialClassesByDate;
              delete trial.trialRegistrations;

              await this.$store.dispatch("trial/updateTrial", {
                trialId: this.trialId,
                update: trial,
              });
              successMessage = "Trial Updated";
            } else {
              console.log("neither add or edit");
            }

            this.$message({
              showClose: true,
              message: successMessage,
              type: "success",
              duration: 5000,
            });

            this.$nextTick(() => {
              this.$refs["addTrialForm"].resetFields();
            });

            this.$router.push({ name: "ownertrials" });
          } catch (error) {
            console.log(error);
            this.$message({
              showClose: true,
              message: "Error ATF1000 creating trial",
              type: "error",
              duration: 5000,
            });
          }
        } else {
          console.log("error submitting!!");
        }

        this.isLoading = false;
      });
    },
    getOwnerLocations(queryString, cb) {
      let queryLocations = this.locations;

      let results = queryString
        ? queryLocations.filter(this.createFilter(queryString))
        : queryLocations;

      cb(results);
    },
    createFilter(queryString) {
      return (location) =>
        location.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
    },
    handleSelect(locationSelected) {
      // set data to values for selected location
      this.addTrialForm.street1 = locationSelected.street1;
      this.addTrialForm.street2 = locationSelected.street2;
      this.addTrialForm.city = locationSelected.city;
      this.addTrialForm.state = locationSelected.state;
      this.addTrialForm.zip = locationSelected.zip;
    },
    async loadTrialLocations() {
      if (this.getUserId && this.getToken) {
        try {
          const response = await getTrialLocations(
            this.getToken,
            this.getUserId
          );
          this.locations = response.data;
        } catch (error) {
          console.log(error);
          this.$message({
            showClose: true,
            message: "Error ATF2000 getting trial locations",
            type: "error",
            duration: 5000,
          });
        }
      }
    },
  },
  created() {
    this.loadTrialLocations();
  },
};
</script>
<style lang="scss" scoped>
.form-wrapper {
  width: 50%;
  margin: 0 auto;

  section {
    display: flex;
    justify-content: flex-start;

    div {
      margin-right: 10px;
    }
  }

  .half {
    width: 48%;
  }

  h1 {
    text-align: center;
  }
}

.buttons {
  text-align: center;

  button {
    margin-right: 10px;
  }
}
</style>
